import { Switch, SwitchProps, FormControl, FormControlLabel, FormHelperText } from '@mui/material';
import { ReactNode } from 'react';
import { RegisterOptions, useController } from 'react-hook-form';

interface SwitchFieldProps extends Omit<SwitchProps, 'onChange' | 'onBlur'> {
  readonly name: string;
  readonly rules?: RegisterOptions;
  readonly helperText?: ReactNode;
}

export const SwitchField = ({ name, rules, ...rest }: SwitchFieldProps) => {
  const {
    field: { ref, value, ...field },
  } = useController({
    name,
    rules,
    disabled: rest.disabled,
    defaultValue: rest.defaultValue,
  });

  return <Switch inputRef={ref} {...rest} {...field} checked={value} />;
};
